import {Pipe, PipeTransform} from '@angular/core';
import {RolesUsuario} from '../models/usuario-sistema.model';

@Pipe({
    name: 'rolUsuario'
})
export class RolUsuarioPipe implements PipeTransform {

    transform(rol: RolesUsuario): string {
        switch (rol) {
            case RolesUsuario.ADMIN: return 'Administrador';
            case RolesUsuario.USUARIO_GB: return 'Usuario Global Berries';
            case RolesUsuario.USUARIO_INDAP: return 'Usuario Indap';
            case RolesUsuario.USUARIO_IICA: return 'Usuario IICA';
            default: return String(rol);
        }
    }

}
