import { Pipe, PipeTransform } from '@angular/core';
import {VisitaUsuarioService} from "../services/visita-usuario.service";

@Pipe({
  name: 'cantidadVisitas'
})
export class CantidadVisitasPipe implements PipeTransform {


  constructor(private visitaService: VisitaUsuarioService) {
  }

  transform(id: string): number {
    return this.visitaService.visitas.filter(visita => visita.usuario.some(usuario => usuario._ref.id === id)).length
  }

}
