import {Component, OnInit} from '@angular/core';
import {ROUTES} from './menu-items';
import {UsuarioSistemaService} from "../../services/usuario-sistema.service";
import {RouteInfo} from "./sidebar.metadata";

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    showMenu = '';
    showSubMenu = '';
    public sidebarnavItems: any[];

    // this is for the open close
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }

    }

    addActiveClass(element: any) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';
        } else {
            this.showSubMenu = element;
        }
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    constructor(
        public usuarioSistemaService: UsuarioSistemaService
    ) {
    }

    // End open close
    ngOnInit() {
        this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }

    testRol(item: RouteInfo) {
        if (item.roles) return item.roles.includes(this.usuarioSistemaService.rol);
        return true;
    }

    logout() {
        this.usuarioSistemaService.logout();
    }
}
