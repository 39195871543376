<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-start mr-auto">
    <li class="nav-item d-none d-md-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>
    </li>

    <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <li *ngIf="usuarioSistemaService.esUsuarioGB" class="nav-item dropdown" ngbDropdown>
        <a ngbDropdownToggle class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span class="d-none d-md-block">Crear
                <i class="fa fa-angle-down"></i>
            </span>
            <span class="d-block d-md-none">
                <i class="fa fa-plus"></i>
            </span>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
            <a class="dropdown-item" [routerLink]="['/visitas/nuevo']">Nueva Visita</a>
            <a class="dropdown-item" [routerLink]="['/usuarios/editor']">Nuevo Usuario</a>
            <div *ngIf="usuarioSistemaService.esAdmin" class="dropdown-divider"></div>
            <a *ngIf="usuarioSistemaService.esAdmin" class="dropdown-item" [routerLink]="['/admin/usuarios/nuevo']">Nuevo Usuario Sistema</a>
        </div>
    </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-end">
    <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Comment -->
    <!-- ============================================================== -->
    <li *ngIf="usuarioSistemaService.esUsuarioGB" class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-calendar-check font-24"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title bg-primary text-white">
                        <h4 class="m-b-0 m-t-5">{{visitasHoy.length}} Visitas</h4>
                        <span class="font-light">Para Hoy</span>
                    </div>
                </li>
                <li>
                    <div class="message-center notifications" [perfectScrollbar]="config">
                        <!-- Message -->
                        <a href="javascript:void(0)" class="message-item" *ngFor="let visita of visitasHoy">
                            <span class="btn btn-circle btn-primary" (click)="navegar(visita)">
                                <i class="ti-location-arrow"></i>
                            </span>
                            <span class="mail-contnet">
                                <h5 class="message-title">Visita a {{visita.usuario[0].nombre}}</h5>
                                <span class="mail-desc">{{visita.estado}}</span>
                                <span class="time">{{visita.fecha | timestamp | date:'shortTime'}}</span>
                            </span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- End Comment -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Messages -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a class="nav-link waves-effect waves-dark" href="javascript:void(0)" (click)="changetTheme.emit()" id="2" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="font-24 mdi mdi-theme-light-dark"></i>

        </a>
    </li>
    <!-- ============================================================== -->
    <!-- End Messages -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <img [src]="usuarioSistemaService.usuario | imagenUsuario | async" alt="user" class="rounded-circle" width="31">
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                <div class="">
                    <img [src]="usuarioSistemaService.usuario | imagenUsuario | async" alt="user" class="rounded-circle" width="60">
                </div>
                <div class="m-l-10">
                    <h4 class="m-b-0">{{ usuarioSistemaService.usuario.nombre }}</h4>
                    <p class=" m-b-0">{{ usuarioSistemaService.usuario.email }}</p>
                    <p class=" m-b-0">{{ usuarioSistemaService.usuario.rol | rolUsuario }}</p>
                </div>
            </div>
            <a class="dropdown-item" [routerLink]="['/usuario-sistema/perfil']">
                <i class="ti-user m-r-5 m-l-5"></i> Mi Perfil</a>
            <div class="dropdown-divider"></div>
            <!--<a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-settings m-r-5 m-l-5"></i> Ajustes</a>-->
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Cerrar Sesión</a>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>
