import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {RolesUsuario} from '../models/usuario-sistema.model';
import {UsuarioSistemaService} from "../services/usuario-sistema.service";

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {

    constructor(private usuarioService: UsuarioSistemaService, private router: Router) {
    }

    canActivate(): boolean {
        if (this.usuarioService.usuario.rol === RolesUsuario.ADMIN) {
            return true;
        }
        this.router.navigate(['/']);
        return false;
    }
}
