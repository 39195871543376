import {DocumentReference} from "@angular/fire/compat/firestore";
import {Comuna} from "../shared/data/regiones-provincias-comunas";
import {AreaIndap} from "../shared/data/areas-indap";
import {Cultivo} from "../services/cultivos.service";


export interface Usuario {
    _id?: string;
    _ref?: DocumentReference,
    tipo: TipoUsuario,
    nombre: string;
    imagen: string,
    rut: string,
    email: string,
    telefono: number,
    internet: boolean,
    teneniaTierra: boolean,
    teneniaTierraTipo: TipoTenenciaTierra,
    direccion: string,
    sector: string,
    comuna: [Comuna],
    coordenadas: Coordenadas,
    areaIndap: [AreaIndap],
    ejecutivoIndap: string
    extencionistaIndap: string,
    inicioActividades: boolean,
    conadi: boolean,
    descripcionProyecto: string,
    inventarioMaquinarias: ItemInventarioMaquinas[],
    infraestructuraProductiva: {
        fuenteAgua: boolean,
        fuentaAguaTipo: string,
        derechosAgua: string,
        sistemaRiego: boolean,
        sistemaRiegoEstado: string,
        fertiRiego: boolean,
        fertiRiegoEstado: string,
        reparacionSugerida: string,
        aguaObservaciones: string
        cercoPerimetral: boolean,
        cercoPerimetralObservaciones: string
        bodegaFitosanitarios: boolean,
        bodegaFitosanitariosObservaciones: string,
        bodegaInsumos: boolean,
        bodegaInsumosObservaciones: string
    },
    frutalesEstablecidos: Cultivo[],
    anexoFotografico: string[],
    gestorProductivo: any[],
    estado: EstadoUsuario


}

export enum TipoTenenciaTierra {
    PROPETARIO = 'Propetario',
    ARRENDATARIO = 'Arrendatario'
}

export interface Coordenadas {
    lat: number,
    lon: number
}

export interface ItemInventarioMaquinas {
    nombre: string,
    estado: string,
    observacion: string
}

export interface ItemFrutalesEstablecidos {
    especie: string,
    variedad: string,
    superficie: number,
    anoPlantacion: number
}

export enum EstadoUsuario {
    ELIMINADO = 'eliminado',
    ACTIVO = 'activo'
}

export enum TipoUsuario {
    INDAP = 'indap',
    IICA = 'iica',
    OTROS = 'otros'
}
