import {Component, AfterViewInit, EventEmitter, Output, OnInit, OnDestroy} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {UsuarioSistemaService} from "../../services/usuario-sistema.service";
import {VisitaUsuarioService} from "../../services/visita-usuario.service";
import {VisitaUsuario} from "../../models/visita-usuario.model";
import {Subscription} from "rxjs";
import {UsuarioService} from "../../services/usuario.service";
import Swal from "sweetalert2";

declare var $: any;

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit, OnInit, OnDestroy {
    @Output() toggleSidebar = new EventEmitter<void>();
    @Output() changetTheme = new EventEmitter<void>();
    public config: PerfectScrollbarConfigInterface = {};

    public showSearch = false;

    visitasHoy: VisitaUsuario[] = [];
    visitasSubscription: Subscription;

    constructor(public usuarioSistemaService: UsuarioSistemaService, private visitaUsuarioService: VisitaUsuarioService, private usuarioService: UsuarioService) {
    }

    async navegar(visita: VisitaUsuario) {
        const usuario = await this.usuarioService.obtenerRef(visita.usuario[0]._ref)
        if (usuario.coordenadas.lon && usuario.coordenadas.lat) {
            Swal.fire({
                title: 'Navegar',
                icon: 'info',
                html:
                    `<a class="btn btn-block btn-primary" target="_blank"
                                                            href="https://www.google.com/maps/search/?api=1&query=${usuario.coordenadas.lat},${usuario.coordenadas.lon}&navigate=yes">
                                            Navegar con Google</a>
                                            <a class="btn btn-block btn-primary" target="_blank"
                                               href="https://waze.com/ul?ll=${usuario.coordenadas.lat},${usuario.coordenadas.lon}&navigate=yes">
                                                Navegar con Waze</a>
                `,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText:
                    'Cancelar',
            })
        }
    }

    // This is for Notifications
    notifications: Object[] = [
        {
            btn: 'btn-danger',
            icon: 'ti-link',
            title: 'Luanch Admin',
            subject: 'Just see the my new admin!',
            time: '9:30 AM'
        },
        {
            btn: 'btn-success',
            icon: 'ti-calendar',
            title: 'Event today',
            subject: 'Just a reminder that you have event',
            time: '9:10 AM'
        },
        {
            btn: 'btn-info',
            icon: 'ti-settings',
            title: 'Settings',
            subject: 'You can customize this template as you want',
            time: '9:08 AM'
        },
        {
            btn: 'btn-primary',
            icon: 'ti-user',
            title: 'Pavan kumar',
            subject: 'Just see the my admin!',
            time: '9:00 AM'
        }
    ];

    // This is for Mymessages
    mymessages: Object[] = [
        {
            useravatar: 'assets/images/users/1.jpg',
            status: 'online',
            from: 'Pavan kumar',
            subject: 'Just see the my admin!',
            time: '9:30 AM'
        },
        {
            useravatar: 'assets/images/users/2.jpg',
            status: 'busy',
            from: 'Sonu Nigam',
            subject: 'I have sung a song! See you at',
            time: '9:10 AM'
        },
        {
            useravatar: 'assets/images/users/2.jpg',
            status: 'away',
            from: 'Arijit Sinh',
            subject: 'I am a singer!',
            time: '9:08 AM'
        },
        {
            useravatar: 'assets/images/users/4.jpg',
            status: 'offline',
            from: 'Pavan kumar',
            subject: 'Just see the my admin!',
            time: '9:00 AM'
        }
    ];

    ngAfterViewInit() {
    }

    logout() {
        this.usuarioSistemaService.logout();
    }

    ngOnDestroy(): void {
        this.visitasSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.visitasSubscription = this.visitaUsuarioService.visitasUsuarioHoy().subscribe(visitas => this.visitasHoy = visitas);
    }
}
