import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usuariosSistema'
})
export class UsuariosSistemaPipe implements PipeTransform {

  transform(value: any[]): string {
    if (value) return value.map(c => c.nombre).join(', ');
    return '';
  }
}
