import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'codigoYouTube'
})
export class CodigoYouTubePipe implements PipeTransform {

  transform(value: string): string {

    let video_id = value.split('v=')[1];
    const ampersandPosition = video_id.indexOf('&');
    if(ampersandPosition != -1) {
      video_id = video_id.substring(0, ampersandPosition);
    }

    return 'https://www.youtube.com/embed/' + video_id;
  }

}
