import {Pipe, PipeTransform} from '@angular/core';
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {Observable} from "rxjs";

@Pipe({
    name: 'storageUrl'
})
export class StorageUrlPipe implements PipeTransform {

    constructor(private storage: AngularFireStorage) {
    }

    transform(path: string): Observable<string> {
        return this.storage.ref(path).getDownloadURL();
    }

}
