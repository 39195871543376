import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import {AdminGuard} from "./guards/admin.guard";
import {LoginGuard} from "./guards/login.guard";


export const Approutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [LoginGuard],
        children: [
            { path: '', redirectTo: 'calendario', pathMatch: 'full' },
            {
                path: 'inicio',
                loadChildren: () => import('./pages/inicio/inicio.module').then(m => m.InicioModule)
            },
            {
                path: 'admin',
                canActivate: [AdminGuard],
                loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: 'usuarios',
                loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosModule)
            },
            {
                path: 'calendario',
                loadChildren: () => import('./pages/calendario/calendario.module').then(m => m.CalendarioModule)
            },
            {
                path: 'visitas',
                loadChildren: () => import('./pages/visitas/visitas.module').then(m => m.VisitasModule)
            },
            {
                path: 'usuario-sistema',
                loadChildren: () => import('./pages/usuario-sistema/usuario-sistema.module').then(m => m.UsuarioSistemaModule)
            },
            {
                path: 'mapa-usuarios',
                loadChildren: () => import('./pages/mapa-usuarios/mapa-usuarios.module').then(m => m.MapaUsuariosModule)
            },
            {
                path: 'biblioteca',
                loadChildren: () => import('./pages/biblioteca/biblioteca.module').then(m => m.BibliotecaModule)
            }
        ]
    },
    {
        path: '',
        component: BlankComponent,
        children: [
            {
                path: 'login',
                loadChildren:
                    () => import('./pages/login/login.module').then(m => m.LoginModule)
            }
        ]
    },
    // {
    //   path: '**',
    //   redirectTo: '/authentication/404'
    // }
];
