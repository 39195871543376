import {Injectable} from '@angular/core';
import {EstadoUsuario, TipoUsuario, Usuario} from "../models/usuario.model";
import {DBService} from "./db.service";
import {map, take} from "rxjs/operators";
import {UsuarioSistemaService} from "./usuario-sistema.service";
import {RolesUsuario} from "../models/usuario-sistema.model";
import {of} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    private coleccion = 'usuarios'

    constructor(private db: DBService, private usuarioService: UsuarioSistemaService) {

    }

    listar() {
        switch (this.usuarioService.rol) {
            case RolesUsuario.ADMIN:
                return this.db.obtenerColeccion<Usuario>(this.coleccion,
                    ref => ref.where('estado', '==', EstadoUsuario.ACTIVO)
                        .orderBy('nombre')
                )
            case RolesUsuario.USUARIO_GB:
                return this.db.obtenerColeccion<Usuario>(this.coleccion,
                    ref => ref.where('estado', '==', EstadoUsuario.ACTIVO)
                        .orderBy('nombre')
                )
            case RolesUsuario.USUARIO_INDAP:
                return this.db.obtenerColeccion<Usuario>(this.coleccion,
                    ref => ref
                        .where('estado', '==', EstadoUsuario.ACTIVO)
                        .where('tipo', '==', TipoUsuario.INDAP)
                        .orderBy('nombre')
                ).pipe(map(usuarios => {
                    return usuarios.filter(usuario => {
                        //console.log(usuario.areaIndap, usuario.nombre)
                        return this.usuarioService.usuario.area.some(area => area.id === usuario.areaIndap[0].id)
                    })
                }))
            case RolesUsuario.USUARIO:
                return this.db.obtenerColeccion<Usuario>(this.coleccion,
                    ref => ref.where('estado', '==', EstadoUsuario.ACTIVO)
                        .orderBy('nombre'))
                    .pipe(map(usuarios => usuarios
                        .filter(usuario => this.usuarioService.usuario.refUsuario.some(ref => ref._ref.id === usuario._id))))
            default:
                return of([]);
        }

    }

    crear(usuario: Usuario) {
        return this.db.crear<Usuario>(usuario, this.coleccion)
    }

    actualizar(usuario: Usuario) {
        return this.db.actualizar<Usuario>(usuario, this.coleccion);
    }

    guardar(usuario: Usuario) {
        if (usuario._id) {
            return this.actualizar(usuario)
        } else {
            delete usuario._id;
            return this.crear(usuario)
        }
    }

    eliminar(usuario: Usuario) {
        usuario.estado = EstadoUsuario.ELIMINADO
        return this.db.actualizar<Usuario>(usuario, this.coleccion)
    }

    obtenerRef(ref) {
        return this.db.obtenerReferenciaDoc<Usuario>(ref).pipe(take(1)).toPromise();
    }

    obtenerId(id) {
        return this.db.obtenerDocPorId<Usuario>(this.coleccion, id).pipe(take(1)).toPromise();
    }

}
