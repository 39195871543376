import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rut'
})
export class RutPipe implements PipeTransform {

  transform(val: string): string {
    const rut = parseInt(val.slice(0,-1));
    const dv = val.slice(-1);
    if (isNaN(Number(rut))) {
      return val.toUpperCase();
    } else {
      return rut.toLocaleString()
          .replace(',', '.')
          .replace(',', '.') + '-' + dv.toUpperCase();
    }
  }

}
