import { Pipe, PipeTransform } from '@angular/core';
import {DocumentReference} from "@angular/fire/compat/firestore";
import {DBService} from "../services/db.service";
import {Observable} from "rxjs";


@Pipe({
  name: 'docRef'
})
export class DocRefPipe implements PipeTransform {

  constructor(private db: DBService) {
  }

  transform(value: DocumentReference, ...args: unknown[]): Observable<any> {
    return this.db.obtenerReferenciaDoc(value);
  }

}
