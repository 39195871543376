import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listaCategorias'
})
export class ListaCategoriasPipe implements PipeTransform {

  transform(value: { _id: string, nombre: string }[]): string {
    return value.map(cat => cat.nombre).join(', ');
  }

}
