import { Pipe, PipeTransform } from '@angular/core';
import {Comuna} from "../shared/data/regiones-provincias-comunas";

@Pipe({
  name: 'comuna'
})
export class ComunaPipe implements PipeTransform {

  transform(value: Comuna[]): string {
    if (value) return value.map(c => c.nombre).join(', ');
    return '';
  }

}
