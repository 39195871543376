import { NgModule } from '@angular/core';
import {ImagenUsuarioPipe} from "./imagen-usuario.pipe";
import {NombreUsuarioPipe} from "./nombre-usuario.pipe";
import {RolUsuarioPipe} from "./rol-usuario.pipe";
import {RutPipe} from "./rut.pipe";
import { DocRefPipe } from './doc-ref.pipe';
import {TimestampPipe} from "./timestamp.pipe";
import { ImagenFirebasePipe } from './imagen-firebase.pipe';
import { CultivoPipe } from './cultivo.pipe';
import { ComunaPipe } from './comuna.pipe';
import { AreaIndapPipe } from './area-indap.pipe';
import {UsuariosSistemaPipe} from "./usuarios-sistema.pipe";
import {TipoUsuarioPipe} from "./tipo-usuario.pipe";
import { CantidadVisitasPipe } from './cantidad-visitas.pipe';
import { ListaCategoriasPipe } from './lista-categorias.pipe';
import { StorageUrlPipe } from './storage-url.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { CodigoYouTubePipe } from './codigo-you-tube.pipe';
import {ArrayObjectElementPipe} from "./array-object-element";



@NgModule({
  declarations: [
      ImagenUsuarioPipe,
      NombreUsuarioPipe,
      RolUsuarioPipe,
      RutPipe,
      DocRefPipe,
      TimestampPipe,
      ImagenFirebasePipe,
      CultivoPipe,
      ComunaPipe,
      AreaIndapPipe,
      UsuariosSistemaPipe,
      TipoUsuarioPipe,
      CantidadVisitasPipe,
      ListaCategoriasPipe,
      StorageUrlPipe,
      SafeUrlPipe,
      CodigoYouTubePipe,
      ArrayObjectElementPipe
  ],
    exports: [
        ImagenUsuarioPipe,
        NombreUsuarioPipe,
        RolUsuarioPipe,
        RutPipe,
        DocRefPipe,
        TimestampPipe,
        ImagenFirebasePipe,
        CultivoPipe,
        ComunaPipe,
        AreaIndapPipe,
        UsuariosSistemaPipe,
        TipoUsuarioPipe,
        CantidadVisitasPipe,
        ListaCategoriasPipe,
        StorageUrlPipe,
        SafeUrlPipe,
        CodigoYouTubePipe,
        ArrayObjectElementPipe
    ]
})
export class PipesModule { }
