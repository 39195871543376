import {Injectable} from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UsuarioSistemaService} from "../services/usuario-sistema.service";

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {

    constructor(private usuarioService: UsuarioSistemaService, private router: Router) {
    }


    async canActivate(route,state: RouterStateSnapshot): Promise<boolean> {
        const logged = await this.usuarioService.logged();
        if (!logged) {
            this.router.navigate(['login'], {queryParams: {url: state.url}});
            return false;
        }
        return true;
    }

}
