import {Pipe, PipeTransform} from '@angular/core';
import firebase from 'firebase/compat/app';

@Pipe({
    name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {

    transform(value: any): Date {
        if (!value) return null;
        return new firebase.firestore.Timestamp(value.seconds, value.nanoseconds).toDate();
    }

}
