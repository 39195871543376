import {DocumentReference} from "@angular/fire/compat/firestore";

export interface UsuarioSistema {
    _id: string;
    _ref: DocumentReference;
    email: string;
    nombre: string;
    telefono: string;
    rol: RolesUsuario;
    estado: EstadoUsuarioSistema;
    configuracion?: ConfiguracionUsuarioSistema;
    imagen: string;
    color: string;
    ultimoAcceso: Date;
    area?: {id: string, nombre: string}[];
    refUsuario?: {_ref: DocumentReference, nombre: string}[];
}

export enum RolesUsuario {
    ADMIN = 'administrador',
    USUARIO_GB = 'usuario_gb',
    USUARIO_INDAP = 'usuario_indap',
    USUARIO_IICA = 'usuario_iica',
    USUARIO = 'usuario'
}

export enum EstadoUsuarioSistema {
    INACTIVO = 'inactivo',
    ACTIVO = 'activo',
    PENDIENTE = 'pendiente',
    ELIMINADO = 'eliminado'
}

export interface ConfiguracionUsuarioSistema {
    theme: string
}
