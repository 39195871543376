import { Pipe, PipeTransform } from '@angular/core';
import {Util} from "leaflet";
import isArray = Util.isArray;

@Pipe({
  name: 'ArrayObjectElement'
})
export class ArrayObjectElementPipe implements PipeTransform {

  transform(value: Object[], ...args: string[]): string {
    if (!isArray(value)) return 'no es un arreglo';
    if (!args[0]) return 'falta atributo';
    return value.map(x => x[args[0]]).join(', ')
  }
}
