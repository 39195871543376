import {Injectable} from '@angular/core';
import {EstadoUsuarioSistema, RolesUsuario, UsuarioSistema} from '../models/usuario-sistema.model';
import {take} from 'rxjs/operators';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {DBService} from "./db.service";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {AngularFireFunctions} from "@angular/fire/compat/functions";

@Injectable({
    providedIn: 'root'
})
export class UsuarioSistemaService {

    private coleccion = 'usuarios-sistema';

    usuario: UsuarioSistema;


    constructor(
        private af: AngularFireAuth,
        private db: DBService,
        private router: Router,
        private afFunctions: AngularFireFunctions
    ) {

    }


    async login(email: string, clave: string) {
        Swal.fire({text: 'Cargando', width: 170, allowOutsideClick: false});
        Swal.showLoading(null);
        const res: any = await this.af.signInWithEmailAndPassword(email, clave)
            .catch(err => {
                    Swal.close();
                    Swal.fire('Error de ingreso', 'Usuario o Contraseña Invalidos', 'error');
                    return;
                }
            );
        if (!res) return;
        const usuario = await this.af.user.pipe(take(1)).toPromise();
        await this.cargarUsuario(usuario.uid)
        await this.router.navigate(['/'])
        Swal.close();
    }

    async logout() {
        await this.router.navigate(['login'])
        await this.af.signOut();
        this.usuario = undefined;

    }

    async logged(): Promise<boolean> {
        const afUser = await this.af.user.pipe(take(1)).toPromise();
        if (!afUser) return false;
        if (!this.usuario) {
            await this.cargarUsuario(afUser.uid);
        }
        this.usuario.ultimoAcceso = new Date();
        await this.db.actualizar({...this.usuario}, this.coleccion)
        return true

    }

    async cargarUsuario(uid: string) {
        this.usuario = await this.db.obtenerDocPorId<UsuarioSistema>(this.coleccion, uid).pipe(take(1)).toPromise();

    }

    private async crear(usuario: UsuarioSistema) {
        const credential = await this.af.createUserWithEmailAndPassword(usuario.email, 'nueva-clave-global-berries-web-app-2020');
        usuario._id = credential.user.uid;
        return await this.db.crearConId<UsuarioSistema>({...usuario}, this.coleccion);
    }

    private actualizar(usuario: UsuarioSistema) {
        return this.db.actualizar({...usuario}, this.coleccion)
    }

    async guardar(usuario: UsuarioSistema) {
        Swal.fire({text: 'Guardando Usuario', width: 170, allowOutsideClick: false});
        Swal.showLoading(null);

        if (usuario._id) {
            await this.actualizar(usuario);
        } else {
            await this.crear(usuario);
            await this.resetearClave(usuario);
        }
        Swal.close();
    }

    async resetearClave(usuario: UsuarioSistema) {
       return this.af.sendPasswordResetEmail(usuario.email);
    }

    async desactivarUsuario(usuario: UsuarioSistema) {
        Swal.fire({text: 'Desactivando Usuario', width: 170, allowOutsideClick: false});
        Swal.showLoading(null);
        const calleable = this.afFunctions.httpsCallable('desactivarUsuario');
        await calleable(usuario._id).pipe(take(1)).toPromise();
        usuario.estado = EstadoUsuarioSistema.INACTIVO;
        await this.actualizar({...usuario});
        Swal.close();
        return usuario;
    }

    async eliminarUsuario(usuario: UsuarioSistema) {
        Swal.fire({text: 'Eliminando Usuario', width: 170, allowOutsideClick: false});
        Swal.showLoading(null);
        const calleable = this.afFunctions.httpsCallable('eliminarUsuario');
        await calleable(usuario._id).pipe(take(1)).toPromise();
        usuario.estado = EstadoUsuarioSistema.ELIMINADO;
        await this.actualizar({...usuario});
        Swal.close();
        return usuario;
    }

    get esAdmin(): boolean {
        return this.usuario.rol === RolesUsuario.ADMIN;
    }

    get esUsuarioGB(): boolean {
        return this.usuario.rol === RolesUsuario.ADMIN || this.usuario.rol === RolesUsuario.USUARIO_GB;
    }



    get rol() {
        return this.usuario.rol;
    }

    get area() {
        return this.usuario.area[0].id;
    }


    get nombre() {
        return this.usuario.nombre;
    }

    get email() {
        return this.usuario.email;
    }

    get id() {
        return this.usuario._id;
    }


    listar() {
        return this.db.obtenerColeccion<UsuarioSistema>(this.coleccion,
            ref => ref.where('estado', '==', EstadoUsuarioSistema.ACTIVO));
    }

    listarInactivos() {
        return this.db.obtenerColeccion<UsuarioSistema>(this.coleccion,
            ref => ref.where('estado', '==', EstadoUsuarioSistema.INACTIVO));
    }

    listarAdmins() {
        return this.db.obtenerColeccion<UsuarioSistema>(this.coleccion,
            ref => ref.where('estado', '==', EstadoUsuarioSistema.ACTIVO)
                .where('rol', '==', RolesUsuario.ADMIN));
    }

    listarUsuariosGB() {
        return this.db.obtenerColeccion<UsuarioSistema>(this.coleccion,
            ref => ref
                .where('estado', '==', EstadoUsuarioSistema.ACTIVO)
                .where('rol', 'in', [RolesUsuario.ADMIN, RolesUsuario.USUARIO_GB])
                .orderBy('nombre')
        );
    }

    obtenerUsuario(id): Promise<UsuarioSistema> {
        return this.db.obtenerDocPorId<UsuarioSistema>(this.coleccion, id).pipe(take(1)).toPromise();
    }

    resetClave(usuario: UsuarioSistema) {
        this.af.sendPasswordResetEmail(usuario.email);
    }

    /*activarUsuario(usuario: UsuarioSistema) {
        usuario.estado = EstadoUsuarioSistema.ACTIVO;
        return new Promise((resolve, reject) => {
            this.dbService.actualizar<UsuarioSistema>(usuario).then(id => {
                this.http.post(environment.API_URL + 'usuario/activar', {usuario, url: window.location.origin}).pipe(take(1)).subscribe();
                resolve(id);
            }).catch(err => reject(err));
        });
    }

    desactivarUsuario(usuario: UsuarioSistema) {
        usuario.estado = EstadoUsuarioSistema.INACTIVO;
        return this.dbService.actualizar<UsuarioSistema>(usuario);
    }

    recuperarClave(email: string) {
        return new Promise((resolve, reject) => {
            this.dbService.obtenerDocPorBusqueda<UsuarioSistema>(this.coleccion, {email, estado: EstadoUsuarioSistema.ACTIVO}, ['email', 'nombre'])
                .then(usuario => {
                    this.http.post(environment.API_URL + 'usuario/recuperarClave',
                        {
                            usuario: {nombre: usuario.nombre, email: usuario.email},
                            url: window.location.origin + '/#/authentication/recover?token='
                        }).pipe(take(1))
                        .toPromise().then(() => resolve());
                }).catch((err) => reject(err));
        });

    }

    cambiarClave(email: string, clave: string) {
        return new Promise((resolve, reject) => {
            this.dbService.obtenerDocPorId(email).then((usuario: any) => {
                this.dbService.actualizar({...usuario, clave}).then(() => resolve());
            });
        }).catch(err => err);
    }


    esAdmin(usuario: UsuarioSistema) {
        return this.loginService.usuario.rol === RolesUsuario.ADMIN;
    }





    obtenerUsuarios() {
        return this.dbService.obtenerColeccion<UsuarioSistema>(this.coleccion, {estado: EstadoUsuarioSistema.ACTIVO})
    }

    permisoMenu(rol: RolesUsuario[]): boolean {
        return rol.includes(this.loginService.usuario.rol)
       /!* let res = false;
        rol.forEach(role => {
            if (this.loginService.usuario.rol.includes(role)) {
                res = true;
            }
        })
        return res;*!/
    }*/

}
