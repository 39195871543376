// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyDQSv_r2gsRXylSBSI3uUXOjMXTfaWurJE",
        authDomain: "global-berries.firebaseapp.com",
        databaseURL: "https://global-berries.firebaseio.com",
        projectId: "global-berries",
        storageBucket: "global-berries.appspot.com",
        messagingSenderId: "126184606407",
        appId: "1:126184606407:web:a5a7673b48357a90070ce2",
        measurementId: "G-PEC7BTTKDT"
    },
    DB_NAME: 'globalberries',
    API_URL: 'http://localhost:4500/'
};
