import {RouteInfo} from './sidebar.metadata';
import {RolesUsuario} from "../../models/usuario-sistema.model";

export const ROUTES: RouteInfo[] = [
    {
        path: '/biblioteca',
        title: 'Biblioteca',
        icon: 'mdi mdi-book-open',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/visitas',
        title: 'Visitas',
        icon: 'mdi mdi-calendar-check',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/calendario',
        title: 'Calendario',
        icon: 'mdi mdi-calendar',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/usuarios',
        title: 'Usuarios',
        icon: 'mdi mdi-account-multiple',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/mapa-usuarios',
        title: 'Mapa Usuarios',
        icon: 'mdi mdi-map',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '',
        title: 'Administración',
        icon: 'mdi mdi-account-key',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/admin/cultivos',
                title: 'Cultivos',
                icon: 'mdi mdi-flower',
                class: '',
                extralink: false,
                submenu: []
            },
           /* {
                path: '/admin/categorias-biblioteca',
                title: 'Categorias Biblioteca',
                icon: 'mdi mdi-format-list-bulleted',
                class: '',
                extralink: false,
                submenu: []
            },*/
            {
                path: '/admin/recomendaciones',
                title: 'Recomendaciones',
                icon: 'mdi mdi-playlist-check',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/admin/mantenedor-categorias',
                title: 'Categorias',
                icon: 'mdi mdi-format-list-bulleted-type',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/admin/usuarios',
                title: 'Usuarios App',
                icon: 'mdi mdi-account',
                class: '',
                extralink: false,
                submenu: []
            },

        ],
        roles: [RolesUsuario.ADMIN]
    }
];
