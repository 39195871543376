import { Pipe, PipeTransform } from '@angular/core';
import {Cultivo} from "../shared/data/cultivos";

@Pipe({
  name: 'cultivo'
})
export class CultivoPipe implements PipeTransform {

  transform(value: Cultivo[]): string {
    if (value) return value.map(c => c.nombre).join(', ');
    return '';
  }
}
