import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference, QueryFn} from '@angular/fire/compat/firestore';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import firebase from "firebase/compat/app";

@Injectable({
    providedIn: 'root'
})
export class DBService {


    constructor(private db: AngularFirestore) {

    }

    private get timestamp() {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    obtenerReferencia<T>(fullPath: string): DocumentReference {
        return this.db.doc<T>(fullPath).ref;
    }

    obtenerReferenciaDoc<T>(ref: DocumentReference): Observable<T> {
        return this.db.doc<T>(ref.path).valueChanges();
    }



    obtenerDocPorId<T>(coleccion: string, id: string): Observable<T> {
        return this.db.collection<T>(coleccion).doc<T>(id).snapshotChanges().pipe(map(doc => {
            const data = doc.payload.data();
            const _id = doc.payload.id;
            const _ref = doc.payload.ref;
            return {_id, _ref, ...data};
        }))
    }

    obtenerDocPorBusqueda<T>(coleccion: string, parametros?: QueryFn): Observable<T> {
        return this.db.collection<T>(coleccion, parametros).snapshotChanges().pipe(map(docs => {
            const data = docs[0].payload.doc.data();
            const _id = docs[0].payload.doc.id;
            const _ref = docs[0].payload.doc.ref;
            return {_id, _ref, ...data};
        }));
    }

    obtenerColeccion<T>(coleccion: string, parametros?: QueryFn): Observable<T[]> {
        return this.db.collection<T>(coleccion, parametros).snapshotChanges().pipe(map(docs => {
            return docs.map(doc => {
                const data = doc.payload.doc.data();
                const _id = doc.payload.doc.id;
                const _ref = doc.payload.doc.ref;
                return {_id, _ref, ...data};
            })
        }));
    }



    async crear<T>(data: T, coleccion: string): Promise<string> {
        delete  data['_id'];
        delete  data['_ref'];
        const newDoc = await this.db.collection<T>(coleccion).add({
            ...data,
            updatedAt: this.timestamp,
            createdAt: this.timestamp
        });
        return newDoc.id;
    }

    async crearConId<T>(data: T, coleccion: string): Promise<string> {
        const id = data['_id'];
        delete  data['_id'];
        delete  data['_ref'];
        const newDoc = await this.db.collection<T>(coleccion).doc<T>(id).set({
            ...data,
            updatedAt: this.timestamp,
            createdAt: this.timestamp
        });
        return id;
    }

    async actualizar<T>(data: T, colecion: string): Promise<string> {
        const id = data['_id'];
        delete  data['_id'];
        delete  data['_ref'];
        await this.db.collection<T>(colecion).doc<T>(id).update({
            ...data,
            updatedAt: this.timestamp
        }).catch(err => console.log(err));
        return id;
    }

    async eliminar<T>(data: T, coleccion: string): Promise<string> {
        await this.db.collection<T>(coleccion).doc<T>(data['_id']).delete();
        return data['_id'];
    }


   /* subirArchivo<T>(docId: string, archivo: File) {
        Swal.fire({text: 'Subiendo Archivo', width: 170, allowOutsideClick: false});
        Swal.showLoading();
        return new Promise((resolve, reject) => {
            this.obtenerDocPorId<T>(docId).then(doc => {
                this.db.putAttachment(docId, archivo.name, doc['_rev'], new Blob([archivo], {type: archivo.type}), archivo.type)
                    .then(res => {
                        Swal.close();
                        resolve(archivo.name)
                    })
                    .catch(error => {
                        Swal.close();
                        Swal.fire('Error', error, 'error');
                    });
            })

        });


    }

    obtenerArchivo<T>(docId: string, idArchivo: string) {
        return this.db.getAttachment(docId, idArchivo);
    }

    verArchivo<T>(docId: string, idArchivo: string) {
        Swal.fire({text: 'Obteniendo Archivo', width: 170, allowOutsideClick: false});
        Swal.showLoading();
        this.db.getAttachment(docId, idArchivo)
            .then(res => {
                Swal.close();
                window.open(window.URL.createObjectURL(res), '_blank')
            }).catch(err => {
            Swal.close();
            Swal.fire('Error', err, 'error');
        })
    }

    eliminarArchivo<T>(docId: string, idArchivo: string) {
        return new Promise((resolve, reject) => {
            this.obtenerDocPorId<T>(docId).then(doc => {
                this.db.removeAttachment(doc['_id'], idArchivo, doc['_rev'])
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        });

    }*/
}
