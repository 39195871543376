import {Pipe, PipeTransform} from '@angular/core';
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {Observable} from "rxjs";

@Pipe({
    name: 'imagenFirebase'
})

export class ImagenFirebasePipe implements PipeTransform {



    constructor(private storage: AngularFireStorage) {
    }

    transform(ref: string, size: string = null): Observable<string> {

        const sizes = ['240', '480' , '640', '1200']

        if (size && sizes.includes(size)) {
            const thumb = '_thumb_'
            const split = ref.split('.');
            const extName = split.pop();
            let filename = split.pop();

            ref = filename + thumb + size + '.' + extName;

        }

        return this.storage.ref(ref).getDownloadURL();
    }

}

enum ThumbSizes {
    'S240' = '240',
    'S480' = '480',
    'S640' = '640',
    'S1200' = '1200'
}
