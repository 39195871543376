import {DocumentReference} from "@angular/fire/compat/firestore";
import {TipoUsuario} from "./usuario.model";

export interface VisitaUsuario {
    _id?: string;
    _ref?: DocumentReference;
    usuario: [{ _ref: DocumentReference, nombre: string }];
    area?: any;
    tipoUsuario?: TipoUsuario
    fecha: Date;
    responsables: { _ref: DocumentReference, nombre: string }[];
    estado: EstadoVisitaUsuario;
    anexoFotografico: string[];
}

export enum EstadoVisitaUsuario {
    PENDIENTE = 'pendiente',
    REALIZADA = 'realizada',
    FALLIDA = 'fallida',
    CANCELADA = 'cancelada',
}
