import { Pipe, PipeTransform } from '@angular/core';
import {AreaIndap} from "../shared/data/areas-indap";

@Pipe({
  name: 'areaIndap'
})
export class AreaIndapPipe implements PipeTransform {

  transform(value: AreaIndap[]): string {
    if (value) return value.map(c => c.nombre).join(', ');
    return '';
  }

}
