import {Pipe, PipeTransform} from '@angular/core';
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {take} from "rxjs/operators";
import {UsuarioSistema} from "../models/usuario-sistema.model";

@Pipe({
    name: 'imagenUsuario'
})
export class ImagenUsuarioPipe implements PipeTransform {

    constructor(private storage: AngularFireStorage) {
    }

    async transform(usuario: UsuarioSistema): Promise<string> {
        if (usuario && usuario.imagen && usuario.imagen.length > 0) {
            return await this.storage.ref(usuario.imagen).getDownloadURL().pipe(take(1)).toPromise();
        } else {
            if (!usuario.nombre) {
                return 'assets/images/users/1.jpg';
            }
            const name = {first: usuario.nombre.split(' ')[0], last: usuario.nombre.split(' ')[1]};

            const canvas = document.createElement('canvas');
            canvas.style.display = 'none';
            canvas.width = 64;
            canvas.height = 64;
            document.body.appendChild(canvas);
            const context = canvas.getContext('2d');
            context.fillStyle = usuario.color || '#e5a000';
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.font = '32px Arial';
            context.fillStyle = '#fff';
            let first, last;
            if (name && name.first && name.first !== '') {
                first = name.first[0];
                last = name.last && name.last !== '' ? name.last[0] : null;
                if (last) {
                    const initials = first + last;
                    context.fillText(initials.toUpperCase(), 9, 44);
                } else {
                    const initials = first;
                    context.fillText(initials.toUpperCase(), 12, 44);
                }
                const data = canvas.toDataURL();
                document.body.removeChild(canvas);
                return data;
            }
        }
    }
}
