import {Pipe, PipeTransform} from '@angular/core';
import {RolesUsuario} from '../models/usuario-sistema.model';
import {UsuarioSistemaService} from '../services/usuario-sistema.service';

@Pipe({
    name: 'nombreUsuario'
})
export class NombreUsuarioPipe implements PipeTransform {

    constructor(private usuarioService: UsuarioSistemaService) {}

    async transform(id: string): Promise<string> {
        if (!id) { return ''}
        const usuario = await this.usuarioService.obtenerUsuario(id);
        return usuario.nombre;
    }

}
