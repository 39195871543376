import {Injectable, OnDestroy} from '@angular/core';
import {DBService} from "./db.service";
import {UsuarioSistemaService} from "./usuario-sistema.service";
import {EstadoVisitaUsuario, VisitaUsuario} from "../models/visita-usuario.model";
import {map, take, takeUntil} from "rxjs/operators";
import {endOfDay,startOfDay} from 'date-fns';
import {RolesUsuario} from "../models/usuario-sistema.model";
import {asyncMap} from "rxjs-async-map";
import {TipoUsuario, Usuario} from "../models/usuario.model";
import {of, Subject} from "rxjs";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import firebase from "firebase/compat/app";

@Injectable({
    providedIn: 'root'
})
export class VisitaUsuarioService implements OnDestroy {

    private coleccion = 'visitas-usuario'
    visitas: VisitaUsuario[] = []
    private unsubcriber = new Subject();

    constructor(private db: DBService, private usuarioService: UsuarioSistemaService, private storage: AngularFireStorage) {
        this.visitasUsuarioSistema().pipe(takeUntil(this.unsubcriber)).subscribe(visitas =>
        this.visitas = visitas)
    }

    ngOnDestroy() {
        this.unsubcriber.next(null);
        this.unsubcriber.complete();
    }

    async asyncFilter(arr, callback) {
        const fail = Symbol()
        return (await Promise.all(arr.map(async item => (await callback(item)) ? item : fail))).filter(i=>i!==fail)
    }

    visitasUsuarioSistema() {
        switch (this.usuarioService.rol) {
            case RolesUsuario.ADMIN:
                return this.db.obtenerColeccion<VisitaUsuario>(this.coleccion, ref => ref.orderBy('fecha', 'desc')).pipe(
                    map(visitas => visitas.filter(visita => visita.estado !== EstadoVisitaUsuario.CANCELADA))
                );
            case RolesUsuario.USUARIO_GB:
                return this.db.obtenerColeccion<VisitaUsuario>(this.coleccion, ref => ref.orderBy('fecha', 'desc')).pipe(
                    map(visitas => {
                        return visitas.filter(visita => visita.responsables.some(res => res._ref.id === this.usuarioService.usuario._id) && visita.estado !== EstadoVisitaUsuario.CANCELADA)
                    })
                );
            case RolesUsuario.USUARIO_INDAP:
                return this.db.obtenerColeccion<VisitaUsuario>(this.coleccion, ref => ref
                    .orderBy('fecha', 'desc')).pipe(
                    map(async visitas => visitas.filter(visita => visita.estado !== EstadoVisitaUsuario.CANCELADA)),
                    asyncMap(async visitas => {
                        let visitasFiltradas = await visitas;
                        visitasFiltradas = await Promise.all(visitasFiltradas.map(async vis => {
                            if (vis.area && vis.tipoUsuario) return vis;
                            const usuario = (await vis.usuario[0]._ref.get()).data() as Usuario;
                            vis.area = usuario.areaIndap[0];
                            vis.tipoUsuario = usuario.tipo;
                            console.log(vis)
                            await this.guardar(vis);
                            return vis
                        }))
                        return visitasFiltradas;
                    }, 2),
                    map(visitas => {
                        return visitas.filter(visita => {
                            return this.usuarioService.usuario.area.some(item => item.id === visita.area.id) && visita.tipoUsuario === TipoUsuario.INDAP;
                        })
                    })
                );
            case RolesUsuario.USUARIO:
                return this.db.obtenerColeccion<VisitaUsuario>(this.coleccion, ref => ref.orderBy('fecha', 'desc'))
                    .pipe(
                    map(visitas => {
                        return visitas.filter(visita => this.usuarioService.usuario.refUsuario.some(res => res._ref.id === visita.usuario[0]._ref.id) && visita.estado !== EstadoVisitaUsuario.CANCELADA)
                    })
                );
            default:
                return of([])
        }
        /*if (this.usuarioService.esAdmin) {
            return this.db.obtenerColeccion<VisitaUsuario>(this.coleccion, ref => ref.orderBy('fecha', 'desc')).pipe(
                map(visitas => visitas.filter(visita => visita.estado !== EstadoVisitaUsuario.CANCELADA))
            );
        }
        return this.db.obtenerColeccion<VisitaUsuario>(this.coleccion, ref => ref.orderBy('fecha', 'desc')).pipe(
            map(visitas => {
                return visitas.filter(visita => visita.responsables.some(res => res._ref.id === this.usuarioService.usuario._id) && visita.estado !== EstadoVisitaUsuario.CANCELADA)
            })
        )*/
    }

    visitasUsuarioHoy() {
        const consulta = this.db.obtenerColeccion<VisitaUsuario>(this.coleccion, ref => {
            return ref
                .where('fecha', '>=', startOfDay(new Date()))
                .where('fecha', '<=', endOfDay(new Date()))
        });
        if (!this.usuarioService.esAdmin) {
            return consulta;
        }

        return consulta.pipe(
            map(visitas => {
                return visitas.filter(visita => visita.responsables.some(res => res._ref.id === this.usuarioService.usuario._id) && visita.estado !== EstadoVisitaUsuario.CANCELADA)
            }));

    }

    visitasUsuario(id) {
        /*return this.db.obtenerColeccion<VisitaUsuario>(this.coleccion, ref => ref.orderBy('fecha', 'desc')).pipe(
            map(visitas => {
                return visitas.filter(visita => visita.usuario.some(res => res._ref.id === id) && visita.estado !== EstadoVisitaUsuario.CANCELADA)
            })
        )*/
        return this.visitas.filter(visita => visita.usuario.some(res => res._ref.id === id) && visita.estado !== EstadoVisitaUsuario.CANCELADA)
    }

    obtenerVisitaId(id) {
        return this.db.obtenerDocPorId<VisitaUsuario>(this.coleccion, id).pipe(take(1)).toPromise();
    }


    guardar(visita: VisitaUsuario) {
        if (visita.fecha instanceof firebase.firestore.Timestamp) {
            visita.fecha = visita.fecha.toDate();
        } else {
            visita.fecha = new Date(visita.fecha)
        }
        if(visita._id) {
            return this.db.actualizar<VisitaUsuario>({...visita}, this.coleccion);
        } else {
            return this.db.crear<VisitaUsuario>({...visita}, this.coleccion);
        }
    }

    cancelar(visita: VisitaUsuario) {
        const edit = {...visita};
        // @ts-ignore
        edit.fecha = edit.fecha.toDate();
        edit.estado = EstadoVisitaUsuario.CANCELADA;
        return this.guardar(edit);
    }

    async obtenerFotos(visita: VisitaUsuario) {
        return await Promise.all(visita.anexoFotografico.map(async ref => {
            const type = await this.storage.ref(ref).getMetadata().pipe(take(1)).toPromise();
            switch (type.contentType.split('/')[0]) {
                case 'image':
                    return {
                        image: await this.obtenerUrl(ref, 1200).catch(() => {this.eliminarFoto(ref, visita); return 'assets/images/no-image.png'}),
                        thumbImage: await this.obtenerUrl(ref, 240).catch(() => {this.eliminarFoto(ref, visita); return 'assets/images/no-image.png'}),
                    };
                case 'video':
                    return {
                        video: await this.obtenerUrl(ref),
                    };
            }

        }))
    }

    async obtenerUrl(ref, size = null) {
        let url;
        if (size) {
            const thumb = '_thumb_'
            const split = ref.split('.');
            const extName = split.pop();
            let filename = split.pop();
            const image = filename + thumb + String(size) + '.' + extName;
            //console.log((await this.storage.ref(image).getMetadata().pipe(take(1)).toPromise()).content_type)
            url = await this.storage.ref(image).getDownloadURL().pipe(take(1)).toPromise()
                .catch(() => this.obtenerUrl(ref));
        } else {
            url = await this.storage.ref(ref).getDownloadURL().pipe(take(1)).toPromise()/*.catch(() => 'assets/images/no-image.png')*/;
        }
        return url;
    }

    async eliminarFoto(ref, visita: VisitaUsuario) {
        const index = visita.anexoFotografico.findIndex(foto => foto === ref);
        if (index >= 0) {
            visita.anexoFotografico.splice(index, 1);
            await this.guardar(visita);
        }
        return null;
    }
}
