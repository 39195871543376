import {Pipe, PipeTransform} from '@angular/core';
import {TipoUsuario} from "../models/usuario.model";

@Pipe({
    name: 'tipoUsuario'
})
export class TipoUsuarioPipe implements PipeTransform {

    transform(rol: TipoUsuario): string {
        switch (rol) {
            case TipoUsuario.INDAP: return 'INDAP';
            case TipoUsuario.IICA: return 'IICA';
            default: return String(rol);
        }
    }

}
